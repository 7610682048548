import type { FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { Illustration } from 'components/basic/Illustration';
import type { TIllustrationTypes } from 'components/basic/Illustration/types';
import { BodyL400, H2 } from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import { Titles } from '../Titles';
import { HELPS_BLOCKS } from './constants';

import s from './HelpsBlock.module.scss';

export const HelpsBlock: FC = () => {
  return (
    <div data-testid="help">
      <Titles
        title="Мы поможем"
        subtitle="Написать книгу нелегко, но мы поддержим в остальном"
        dataTestIdTitle="help_title"
        dataTestIdSubtitle="help_subtitle"
      />

      <div className={s.blocks}>
        {HELPS_BLOCKS.map(({ id, title, text, icon, href }) => (
          <ALink
            href={href}
            target="_blank"
            className={s.block}
            key={id}
            dataTestId="help_block"
          >
            <Illustration
              imageType={icon as TIllustrationTypes}
              width={112}
              height={112}
            />

            <div className={s.info}>
              <H2 className={s.title}>{title}</H2>
              <BodyL400>{typografFixText(text)}</BodyL400>
            </div>
          </ALink>
        ))}
      </div>
    </div>
  );
};
