import type { FC } from 'react';

import { HighlightTitle, HighlightSubtitle } from 'components/basic/Typography';

import { typografFixText } from 'utils/typograf';

import s from './Titles.module.scss';

interface ITitlesProps {
  title: string;
  subtitle: string;
  dataTestIdTitle?: string;
  dataTestIdSubtitle?: string;
}

export const Titles: FC<ITitlesProps> = ({
  title,
  subtitle,
  dataTestIdTitle,
  dataTestIdSubtitle,
}) => {
  return (
    <div className={s.titles}>
      <HighlightTitle
        Tag="h2"
        className={s.title}
        data-testid={dataTestIdTitle}
      >
        {title}
      </HighlightTitle>
      <HighlightSubtitle
        Tag="p"
        className={s.subtitle}
        data-testid={dataTestIdSubtitle}
      >
        {typografFixText(subtitle)}
      </HighlightSubtitle>
    </div>
  );
};
